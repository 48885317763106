import React, {useEffect} from "react";
import {Outlet,useLocation,useNavigate} from "react-router-dom";
import NavMenu from "../UI/molecules/NavMenu";
import MenuItem from "../UI/atoms/MenuItem";
import {useAuth} from "../../utils/useAuth";
import ProfileMenu from "./ProfileMenu";

const WrappedOutlet = Outlet;

const TopNav = () => {
  const {user} = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      // do nothing
    } else {
      navigate('/login');
    }
  }, []);
  let menu;
  if (user) {
    menu = (
      <NavMenu>
        <MenuItem path="/utility" text="Utilities" />
        &nbsp;|&nbsp;<MenuItem path="/maintenance" text="Maintain Data" />
      </NavMenu>
    );
  } else {
    if (location.pathname !== "/login") {
      menu = (
        <NavMenu>
          <MenuItem path="/login" text="Login" />
        </NavMenu>
      );
    }
  }
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const prefix = (environment === 'PROD') ? '' : '(' + environment + ') ';
  const title = prefix + "Resident Utility Billing System";
  return (
    <div>
      <div style={{display:"flex",alignItems:"center"}}>
        <img src="sami_logo.png" alt="SAMI Logo" width="100px" height="100px"/>
        <div style={{flex:"60%",marginLeft:"10px"}}>
          <h3 id="mainHeader">{title}</h3>
        </div>
        <div style={{float:"right"}}>
          <ProfileMenu />
        </div>
      </div>
      {menu}
      <main>
        <WrappedOutlet />
      </main>
    </div>
  );
}

export default TopNav;