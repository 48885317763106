import React from 'react';
import {Outlet} from "react-router-dom";
import NavMenu from "../../UI/molecules/NavMenu";
import MenuItem from "../../UI/atoms/MenuItem";
import {Menu} from "@mui/material";

const WrappedOutlet = Outlet;

function MaintenanceMenu() {
  return (
    <div>
      <NavMenu>
        <MenuItem path="/maintenance/clients" text="Clients" header="Maintain Clients" />
        &nbsp;|&nbsp;<MenuItem path="/maintenance/providers" text="Providers" header="Maintain Providers" />
        &nbsp;|&nbsp;<MenuItem path="/maintenance/portfolios" text="Portfolios" header="Maintain Portfolios" />
        &nbsp;|&nbsp;<MenuItem path="/maintenance/buildings" text="Buildings" header="Maintain Buildings" />
        &nbsp;|&nbsp;<MenuItem path="/maintenance/units" text="Units" header="Maintain Units" />
        &nbsp;|&nbsp;<MenuItem path="/maintenance/utilityaccounts" text="Utility Accounts" header="Maintain Utility Accounts" />
        &nbsp;|&nbsp;<MenuItem path="/maintenance/bills" text="Bills" header="Maintain Bills" />
        &nbsp;|&nbsp;<MenuItem path="/maintenance/smartmeters" text="Smart Meters" header="Maintain Smart Meter Readings" />
        {/* SAMI-108 &nbsp;|&nbsp;<MenuItem path="/maintenance/fees" text="Fees" header="Maintain Fees" />*/}
        &nbsp;|&nbsp;<MenuItem path="/maintenance/tenants" text="Tenants" header="Maintain Tenants" />
        &nbsp;|&nbsp;<MenuItem path="/maintenance/pwaccounts" text="PW Accounts" header="Maintain PropertyWare Accounts" />
      </NavMenu>
      <WrappedOutlet />
    </div>
  );
}

export default MaintenanceMenu;