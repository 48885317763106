import React from "react";
import {Navigate} from "react-router-dom";
import {useAuth} from "../../../utils/useAuth";
import Message from "../admin/etc/Message";
import {useState,onEnter} from "../../../utils/ReactUtils";
import {Button} from "@mui/material";
import TextInput from "../admin/etc/TextInput";
import UsersService from "../../../services/UsersService";

const Login = () => {
  const [loginInput,setLoginInput] = useState("","loginInput");
  const [password,setPassword] = useState("","password");
  const [message,setMessage] = useState(null, 'message');
  const [isError,setIsError] = useState(false, "isError");

  const {user,login} = useAuth();
  if (user) {
    return <Navigate to="/utility/calculate" />;
  }

  const setError = msg => {setMessage(msg); setIsError(true)};

  const attemptToLogin = async (event) => {
    event.preventDefault();
    try {
      const user = (await UsersService.login(loginInput,password))['data'];
      if (user['is_enabled'] && !user['is_api_user']) {
        login(user);
      }
    } catch (error) {
      console.error('caught this error:',error);
      setError(error?.response?.data?.message || error?.message);
      return;
    }
  };

  return <div style={{borderStyle:"outset",
    padding:"10px",
    minHeight:"100%",
    width:"25%",
  }}>
    <Message message={message} isError={isError} />
    <div>
      <div>
        <TextInput title="Login" index="1"
                   value={loginInput} setValue={setLoginInput}
                   onKeyDown={e=>onEnter(e,attemptToLogin)}
                   autoFocus
        />
        <TextInput title="Password" index="2"
                   value={password} setValue={setPassword} isPassword
                   onKeyDown={e=>onEnter(e,attemptToLogin)}
        />
      </div>
      <div style={{padding:"20px 30px 0px 0px"}}>
        <Button onClick={attemptToLogin}>Login</Button>
      </div>
    </div>
  </div>;
};

export default Login;