import React from 'react';
import {Outlet} from "react-router-dom";
import NavMenu from "../../UI/molecules/NavMenu";
import MenuItem from "../../UI/atoms/MenuItem";

const WrappedOutlet = Outlet;

function UtilityBillingMenu() {
  return (
    <div>
      <NavMenu>
        <MenuItem path="/utility/calculate" text="Calculate Utilities" header="Calculate Utilities" />
        &nbsp;|&nbsp;<MenuItem path="/utility/imports" text="Imports" header="Utility Imports" />
        &nbsp;|&nbsp;<MenuItem path="/utility/exports" text="Exports" header="Saved Exports" />
      </NavMenu>
      <WrappedOutlet />
    </div>
  );
}

export default UtilityBillingMenu;