import React from "react";
import SavedExportsService from "../../../services/SavedExportsService";
import AbstractMaintain from "./AbstractMaintain";
import RubsExpandingViewer from "../../UI/organisms/RubsExpandingViewer";
import {
  EXPORT_TYPE,
  getExportFileName,
  generateRUBSExport,
  generateInactiveTenantsExport,
  generateBillsImport
} from "../../../utils/ExportUtils";

const MaintainSavedExports = (props) => {
  const columnDefs = [
    {
      Header: "Export",
      accessor: "export_name",
      type: "text",
      isEditable: false,
    },
    {
      Header: "Timestamp",
      accessor: "generated_at",
      type: "text",
      isEditable: false,
    },
    {
      Header: "Size (bytes)",
      accessor: "export_bytes",
      type: "text",
      isEditable: false,
    },
    {
      Header: "Parameters",
      accessor: "export_parameters",
      type: "text",
      isEditable: false,
      renderCell: (value) => {
        if (!value) {
          return value;
        }
        return <RubsExpandingViewer value={value} />;
      }
    },
    {
      Header: "Download",
      accessor: "export_data",
      type: "text",
      isEditable: false,
      renderCell: (value, props) => {
        if (!value) {
          return value;
        }
        const row = props.row.values;
        const exportType = row.export_name;
        const exportVersion = row.export_version;
        const {startDate, endDate} = JSON.parse(row.export_parameters);
        const filename = getExportFileName(exportType, startDate, endDate);
        const downloadFile = (event) => {
          event.preventDefault();
          let generateExport;
          switch (exportType) {
            case EXPORT_TYPE.UTILITIES:
              generateExport = generateRUBSExport;
              break;
            case EXPORT_TYPE.INACTIVE_TENANTS:
              generateExport = generateInactiveTenantsExport;
              break;
            case EXPORT_TYPE.BILLS_IMPORT:
              generateExport = generateBillsImport;
              break;
            default:
              throw Error("Invalid exportType: " + exportType);
          }
          generateExport(value, startDate, endDate, exportVersion);
        }
        return <a href="#" onClick={downloadFile}>
          {filename}
        </a>;
      }
    },
  ];
  return AbstractMaintain(props, {
    columnDefs,
    dataService: SavedExportsService,
    dataDescriptionStr: "Exports",
    allowEdit: false,
  });
};

export default MaintainSavedExports;
