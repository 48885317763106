import AbstractService from "./AbstractService";

class SavedExportsService extends AbstractService {

  constructor() {
    super("savedexports");
  }

}

export default new SavedExportsService();