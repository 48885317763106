import React from 'react';
import {useState} from '../../../utils/ReactUtils';
import CalcChooseFilters from "./CalcChooseFilters";
import CalcReview from "./CalcReview";
import CalcError from "./CalcError";
import CalcGenXLS from "./CalcGenXLS";
import CalcInfo from "./CalcInfo";
import CalcService from "../../../services/CalcService";
import DefaultReportingDateRange from "../../../utils/DefaultReportingDateRange";

const DEFAULT_ELECTRIC_ADJUSTMENT_RATE = 1;

const CalculateUtilities = () => {
  const [previousWednesday, previousThursday] = DefaultReportingDateRange.calculate();

  const [startDate,setStartDate] = useState(previousThursday,'startDate');
  const [endDate,setEndDate] = useState(previousWednesday,'endDate');
  const [electricAdjustmentRate,setElectricAdjustmentRate] = useState(DEFAULT_ELECTRIC_ADJUSTMENT_RATE,'electricAdjustmentRate');
  const [activeStep,setActiveStep] = useState(null,'activeStep');
  const [dataResult,setDataResult] = useState(null,'dataResult');
  const [errorMessage,setErrorMessage] = useState(null,'errorMessage');

  React.useEffect( () => {
    if (activeStep == null || activeStep === 'generateXLS') {
      return;
    }
    const doCalculationCall = async () => {
      setDataResult(null);
      const _data = (await CalcService.doCalculations({startDate,endDate,electricAdjustmentRate,dataset:activeStep}))['data'];
      if (_data['error']) {
        setErrorMessage(_data['error'])
      } else {
        setDataResult(_data);
      }
    };
    doCalculationCall().catch(error => {
      console.error(error);
      const msg = error.response?.data?.message ? error.response.data.message
        : error.message ? error.message : "Unknown error; Check logs for more details.";
      setErrorMessage(msg);
    });
  }, [activeStep]);
  // TODO filter execution by client, building, unit, provider, utility-type, tenant(s), etc.?

  const reset = () => {
    setStartDate(previousThursday);
    setEndDate(previousWednesday);
    setElectricAdjustmentRate(DEFAULT_ELECTRIC_ADJUSTMENT_RATE);
    setActiveStep(null);
    setDataResult(null);
    setErrorMessage(null);
  };

  let step, parms, info = null;
  if (errorMessage) {
    parms = {reset, errorMessage};
    step = <CalcError {...parms} />;
  } else if (activeStep) {
    if (activeStep === 'generateXLS') {
      parms = {startDate,endDate,electricAdjustmentRate,reset,setErrorMessage};
      step = (
        <div>
          <CalcGenXLS {...parms} />
        </div>
      );
    } else {
      parms = {dataResult,activeStep,setActiveStep,reset};
      step = (
        <div>
          <CalcReview {...parms} />
        </div>
      );
    }
  } else {
    parms = {startDate,setStartDate,endDate,setEndDate,setElectricAdjustmentRate};
    step = <CalcChooseFilters {...parms}
                              handleSubmit={()=>setActiveStep('periodUtilityDistribution')}
    />;
    info = <CalcInfo />;
  }

  return (
    <div>
      <h2>Calculate Tenant Utilities</h2>
      {step}
      {info}
    </div>
  );
};

export default CalculateUtilities;