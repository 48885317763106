import React from "react";
import {useState} from '../../../utils/ReactUtils';
import CalcService from "../../../services/CalcService";
import Button from "@mui/material/Button";
import {
  generateRUBSExport, generateInactiveTenantsExport, generateBillsImport
} from "../../../utils/ExportUtils";

const CalcGenXLS = (params) => {
  const {
    startDate,
    endDate,
    electricAdjustmentRate,
    reset,
    setErrorMessage,
  } = params;

  const [isLoading,setIsLoading] = useState(true,'loading');

  React.useEffect( () => {
    if (!isLoading) {
      return;
    }
    const doCalculationCall = async () => {
      setIsLoading(true);
      const _data = (await CalcService.doCalculations({startDate,endDate,electricAdjustmentRate,dataset:'generateXLS'}))['data'];
      if (_data['error']) {
        setErrorMessage(_data['error'])
      } else {
        const generateExport = (key, func) => {
          const data = _data[key];
          const version = _data['versions'][key];
          func(data, startDate, endDate, version);
        }
        generateExport('generateXLS', generateRUBSExport);
        generateExport('generateXLS_inactiveTenants', generateInactiveTenantsExport);
        generateExport('billsImport', generateBillsImport);
      }
      setIsLoading(false);
    };
    doCalculationCall().catch(error => {
      setIsLoading(false);
      console.error(error);
      setErrorMessage(error.message ? error.message : "Unknown error; Check logs for more details.");
    });
  },[isLoading]);

  if (isLoading) {
    return <div>Loading...</div>
  }
  return <div>
    <div style={{display:"flex"}}>
      <Button onClick={reset}>Reset</Button>
    </div>
    <span>Your PropertyWare export has been generated.</span>
  </div>;
};

export default CalcGenXLS;