import React from "react";
import * as XLSX from "xlsx";
import {saveAs} from 'file-saver';

const RUBS_XLS_HEADER_ROW = [
  'Lease','Date','Amount','Account','Comments',
  'Payment Type','Payment Ref No','Payment Comments',
  'Payment Account','Portfolio\\Building\\Unit',
  'Tenant Status'
];
const BILLS_IMPORT_HEADER_ROW = [
  'Vendor','Portfolio','Building','Unit',
  'Date','Amount','Account','Split Comments',
  'Bill Description','Ref #','Payment Account','Payment Type',
  'Payment Check No','Payment Comments','Work Order #'
];

const hasData = arr => arr && arr.length > 0;

const convertToSheetJSStream = (data, buildRowFn, wbTitle, headerRow) => {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: wbTitle,
  };
  wb.SheetNames.push("data");
  const rows = [];
  rows.push(headerRow);
  for (const datum of data) {
    rows.push(buildRowFn(datum));
  }
  const ws = XLSX.utils.aoa_to_sheet(rows);
  wb.Sheets["data"] = ws;
  return XLSX.write(wb, {bookType:'xlsx', type:'binary'});
};

const streamToArrayBuffer = (octetStream) => {
  const buffer = new ArrayBuffer(octetStream.length);
  const view = new Uint8Array(buffer);
  for (let i=0; i<octetStream.length; i++) {
    view[i] = octetStream.charCodeAt(i) & 0xFF;
  }
  return buffer;
};

const saveStream = (stream, fileName) => {
  const buffer = streamToArrayBuffer(stream);
  saveAs(new Blob([buffer],{type:"application/octet-stream"}), fileName);
}

const convertBillsImportToSheetJSStream = (billsImport, startDate, endDate) => {
  const convertBillsImportRecord = (billsImportRecord) => {
    const {
      Vendor:vendor,
      Portfolio:portfolio,
      Building:building,
      Unit:unit,
      Date:date,
      Amount:amount,
      Account:account,
      "Split Comments":splitComments,
      "Bill Description":billDescription,
      "Ref #":refNo,
    } = billsImportRecord;
    const roundedAmount = Math.round((amount+Number.EPSILON)*100)/100;
    return [vendor,portfolio,building,unit,date,roundedAmount,account,splitComments
      ,billDescription
      ,refNo
      ,// Payment Account
      ,// Payment Type
      ,// Payment Check No
      ,// Payment Comments
      ,// Work Order #
    ];
  };
  const wbTitle = "RUBS Bills " + startDate + " to " + endDate;
  return convertToSheetJSStream(billsImport, convertBillsImportRecord, wbTitle, BILLS_IMPORT_HEADER_ROW);
};

const convertRUBSDataToSheetJSStream = (tenantTotalCostSummary, startDate, endDate,) => {
  let chargeDate = new Date();
  chargeDate.setHours(12);
  const [year,month,day] = chargeDate.toISOString().split('T')[0].split('-');
  chargeDate = month + "/" + day + "/" + year;
  const convertTenantRecord = (tenantRecord) => {
    const {leaseName,amount,pwAccount,comment,tenantStatus} = tenantRecord;
    const roundedAmount = Math.round((amount+Number.EPSILON)*100)/100;
    return [leaseName,chargeDate,roundedAmount,pwAccount,comment,,,,,,tenantStatus];
  };
  const wbTitle = "RUBS Charges " + startDate + " to " + endDate;
  return convertToSheetJSStream(tenantTotalCostSummary, convertTenantRecord, wbTitle, RUBS_XLS_HEADER_ROW);
};

/*
 * NOTE: EXPORT_VERSION_STAMP, EXPORT_TYPE, and EXPORT_VERSION
 *       are duplicated in lib/server/src/dao/etc/Exports.js
 */
const EXPORT_VERSION_STAMP = Object.freeze({
  "20241104": "20241104"
});
const EXPORT_TYPE = Object.freeze({
  UTILITIES: 'UTILITIES',
  INACTIVE_TENANTS: 'INACTIVE_TENANTS',
  BILLS_IMPORT: 'BILLS_IMPORT'
});
const EXPORT_VERSION = Object.freeze({
  UTILITIES: EXPORT_VERSION_STAMP["20241104"],
  INACTIVE_TENANTS: EXPORT_VERSION_STAMP["20241104"],
  BILLS_IMPORT: EXPORT_VERSION_STAMP["20241104"]
});


const EXPORT_PREFIX = Object.freeze({
  UTILITIES: 'RUBS',
  INACTIVE_TENANTS: 'RUBS_InactiveTenants',
  BILLS_IMPORT: 'RUBS_Bills_Import'
});

const getExportFileName = (exportType, startDate, endDate) => {
  const prefix = EXPORT_PREFIX[exportType];
  if (!prefix) {
    throw Error("Invalid exportType: " + exportType);
  }
  return prefix + "_" + startDate.replaceAll('-','')
    + "_" + endDate.replaceAll('-','') + ".xlsx";
};

const generateExport = (data, exportType, startDate, endDate, convertFunc) => {
  if (hasData(data)) {
    const filename = getExportFileName(exportType, startDate, endDate);
    const stream = convertFunc(data, startDate, endDate);
    saveStream(stream, filename);
  }
}
const filterInvalidRows = el => {
  return el.leaseName && el.amount && el.amount > 0;
}
const generateRUBSExport = (data, startDate, endDate, version=EXPORT_VERSION_STAMP["20241104"]) => {
  if (version === EXPORT_VERSION_STAMP["20241104"]) {
    const filteredData = data.filter(filterInvalidRows);
    generateExport(filteredData, EXPORT_TYPE.UTILITIES, startDate, endDate, convertRUBSDataToSheetJSStream);
  } else {
    throw Error("Invalid version: " + version);
  }
}
const generateInactiveTenantsExport = (data, startDate, endDate, version=EXPORT_VERSION_STAMP["20241104"]) => {
  if (version === EXPORT_VERSION_STAMP["20241104"]) {
    const filteredData = data.filter(filterInvalidRows);
    generateExport(filteredData, EXPORT_TYPE.INACTIVE_TENANTS, startDate, endDate, convertRUBSDataToSheetJSStream);
  } else {
    throw Error("Invalid version: " + version);
  }
};
const generateBillsImport = (data, startDate, endDate, version=EXPORT_VERSION_STAMP["20241104"]) => {
  if (version === EXPORT_VERSION_STAMP["20241104"]) {
    generateExport(data, EXPORT_TYPE.BILLS_IMPORT, startDate, endDate, convertBillsImportToSheetJSStream);
  } else {
    throw Error("Invalid version: " + version);
  }
}



export {
  EXPORT_TYPE,
  getExportFileName,
  generateRUBSExport,
  generateInactiveTenantsExport,
  generateBillsImport
}